import {IBill} from "./bills";
import {IPayment} from "./payments";
import {IBrand} from "./brands";
import {IOnHoldProduct} from "./onHoldProduct";
import {IUser} from "./user";
import {IClientOrder} from "./clientsOrders";
import {IClientGoal} from "./clientGoals";
import {IClientActivity} from "./clientActivities";
import moment from "moment";

export interface IClientFilter {
    regions: number[]
    categories: string[]
    statusList: string[]
    types: string[]
    name: string,
    focus: 'only' | 'excluded' | ''
}

export class ClientFilter implements IClientFilter {
    regions: number[] = []
    categories: string[] = []
    statusList: string[] = []
    types: string[] = []
    name: string = ''
    focus: 'only' | 'excluded' | '' = ''
}

interface IFinancialState {
    placedValue: number
    placedBaseValue: number
    paid: number
    gratisAmount: number
    scheduleValue: number
    realizationCoef: number
    clientInitialStateValue: number

    balance: number | null
    balanceWithInitialState: number | null
    toPaid: number | null
    toPaidWithInitialState: number | null
    placedToPayday: number | null
}

export class FinancialState implements IFinancialState {
    gratisAmount: number
    paid: number
    placedValue: number
    placedBaseValue: number
    realizationCoef: number = NaN
    scheduleValue: number
    clientInitialStateValue: number

    balanceWithInitialState: number | null = null
    balance: number | null = null
    toPaid: number | null = null
    toPaidWithInitialState: number | null = null
    placedToPayday: number | null = null

    constructor(uncomputed: IFinancialState) {
        this.placedValue = uncomputed.placedValue
        this.placedBaseValue = uncomputed.placedBaseValue
        this.paid = uncomputed.paid
        this.gratisAmount = uncomputed.gratisAmount
        this.scheduleValue = uncomputed.scheduleValue // ?? 0
        this.clientInitialStateValue = uncomputed.clientInitialStateValue
        this.placedToPayday = uncomputed.placedToPayday

        this.toPaid = uncomputed.placedToPayday! - uncomputed.paid;
        this.balance = uncomputed.placedValue - uncomputed.paid;

        this.balanceWithInitialState = this.balance
        this.toPaidWithInitialState = this.toPaid
        if (uncomputed.clientInitialStateValue) {
            this.balanceWithInitialState += uncomputed.clientInitialStateValue
            this.toPaidWithInitialState += uncomputed.clientInitialStateValue
        }

        if (this.scheduleValue !== 0) {
            this.realizationCoef = this.placedBaseValue / this.scheduleValue
        }
        
    }
}

export interface IClientComment {
    id: string
    client: IClient
    // clientId: string
    // clientName: string
    content: string
    user: IUser
    createdAt: Date
    updatedAt: Date
}

export interface IClientEmployee {
    id: string,
    clientId: string
    name: string
    job: string
    birthday: string
    thisYearBirthday: string
    telNumber: string
    note: string
    client: IClient | null
}

export class ClientEmployee implements IClientEmployee {
    birthday = ''
    thisYearBirthday = ''
    clientId = ''
    id = ''
    job = ''
    name = ''
    note = ''
    telNumber = ''
    client: IClient | null = null

    constructor(employee?: IClientEmployee) {
        if (employee) {
            this.birthday = employee.birthday
            this.thisYearBirthday = moment(employee.birthday).set('year', moment().year()).format('YYYY-MM-DD')
            this.clientId = employee.clientId
            this.id = employee.id
            this.job = employee.job
            this.name = employee.name
            this.note = employee.note
            this.telNumber = employee.telNumber
            this.client = employee.client
        }
    }
}


export interface IClient {
    id: string | null
    name: string | null
    category: string | null
    type: string | null
    status: string | null
    isInFocus: boolean
    address: string | null
    city: string | null
    postalCode: string | null
    telNumber: string | null
    privateNote: string | null
    publicNote: string | null
    contactInfo: string | null
    email: string | null
    currentAccount: string | null
    paymentNote: string | null
    regionName: string | null
    regionId: number | null
    pricelistCategoryId: number | null,
    pricelistCategoryName: string | null,
    pricelistCategoryIsSelling: boolean | null,
    // pricelistCategoryIsDefault: boolean | null,
    // pricelistCategoryIsBase: boolean | null,
    financialState: IFinancialState | null
    createdAt: Date | null
    updatedAt: Date | null
    bills: IBill[] | null
    payments: IPayment[] | null
    brands: IBrand[] | null
    onHoldProducts: IOnHoldProduct[] | null
    comments: IClientComment[] | null
    employees: IClientEmployee[] | null
    orders: IClientOrder[] | null
    goals: IClientGoal[] | null
    activities: IClientActivity[] | null
    agent: IUser | null
}

export class Client implements IClient {

    constructor(clientFormValues: IClientFormValues) {
        Object.assign(this, clientFormValues)
    }

    id: string | null = null
    address: string | null = null
    category: string | null = null
    city: string | null = null
    contactInfo: string | null = null
    currentAccount: string | null = null
    email: string | null = null
    name: string | null = null
    paymentNote: string | null = null
    postalCode: string | null = null
    privateNote: string | null = null
    publicNote: string | null = null
    status: string | null = null
    isInFocus: boolean = false
    telNumber: string | null = null
    type: string | null = null
    regionId: number | null = null
    regionName: string | null = null
    pricelistCategoryId: number | null = null
    pricelistCategoryName: string | null = null
    pricelistCategoryIsSelling: boolean | null = null
    // pricelistCategoryIsDefault: boolean | null = null
    // pricelistCategoryIsBase: boolean | null = null
    createdAt: Date | null = null
    updatedAt: Date | null = null
    financialState: IFinancialState | null = null
    bills: IBill[] | null = null
    payments: IPayment[] | null = null
    brands: IBrand[] | null = null
    onHoldProducts: IOnHoldProduct[] | null = null
    comments: IClientComment[] | null = null
    employees: IClientEmployee[] | null = null
    orders: IClientOrder[] | null = null
    goals: IClientGoal[] | null = null
    activities: IClientActivity[] | null = null
    agent: IUser | null = null
}

export interface IClientFormValues extends Partial<IClient> {
    regionName: string
    agentId: string
}

export class ClientFormValues implements IClientFormValues {
    id: string | null = null
    name: string = ''
    category: string = ''
    type: string = ''
    status: string = ''
    address: string = ''
    city: string = ''
    postalCode: string = ''
    telNumber: string = ''
    privateNote: string = ''
    publicNote: string = ''
    contactInfo: string = ''
    email: string = ''
    currentAccount: string = ''
    paymentNote: string = ''
    regionName: string = ''
    regionId: number = NaN
    pricelistCategoryId: number | null = null
    agentId = ''

    constructor(client?: IClient) {
        if (client) {
            this.id = client.id
            this.name = client.name ?? ''
            this.category = client.category ?? ''
            this.type = client.type ?? ''
            this.status = client.status ?? ''
            this.address = client.address ?? ''
            this.city = client.city ?? ''
            this.postalCode = client.postalCode ?? ''
            this.telNumber = client.telNumber ?? ''
            this.privateNote = client.privateNote ?? ''
            this.publicNote = client.publicNote ?? ''
            this.contactInfo = client.contactInfo ?? ''
            this.email = client.email ?? ''
            this.currentAccount = client.currentAccount ?? ''
            this.paymentNote = client.paymentNote ?? ''
            this.regionName = client.regionName ?? ''
            this.regionId = client.regionId ?? NaN
            this.pricelistCategoryId = client.pricelistCategoryId
            this.agentId = client.agent?.id ?? ''

        }
    }

}