import React, {FunctionComponent, useContext} from 'react';
import {Menu} from "semantic-ui-react";
import {Link, NavLink} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {ClientGoalType} from "../../../core/models/clientGoals";
import {EmployeeGoalType} from "../../../core/models/employeeGoals";
import {clientGoalNavbarIdFromType} from "../../clientGoals/dashboard/ClientGoalsDashboard";

interface IProps {
    // loading?: boolean
}

const ScheduleSectionMenu: FunctionComponent<IProps> = (props) => {
    const rootStore = useContext(RootStoreContext)
    const { currentTraffic } = rootStore.trafficsStore
    const { selectedItem } = rootStore.navbarStore

    return (
        <>
            <Menu.Item
                as={NavLink}
                to={`/${currentTraffic?.id}/clientSchedules`}
            >
                Plan prometa
            </Menu.Item>

            <Menu.Item
                // disabled={anyTypeClientGoalsLoading}
                as={Link}
                active={selectedItem === clientGoalNavbarIdFromType(ClientGoalType.classic)}
                to={`/${currentTraffic?.id}/clientGoals?type=${ClientGoalType.classic}`} >
                Ciljevi
            </Menu.Item>

            <Menu.Item
                as={Link}
                active={selectedItem === 'employeeGoals'}
                to={`/${currentTraffic?.id}/employeeGoals`} >
                Tekuće obaveze
            </Menu.Item>

            <Menu.Item
                as={Link}
                active={selectedItem === 'employeeGoals-private'}
                to={`/${currentTraffic?.id}/employeeGoals?type=${EmployeeGoalType.private}`} >
                Privatne obaveze
            </Menu.Item>

            <Menu.Item
                // disabled={anyTypeClientGoalsLoading}
                as={Link}
                active={selectedItem === clientGoalNavbarIdFromType(ClientGoalType.clientRequest)}
                to={`/${currentTraffic?.id}/clientGoals?type=${ClientGoalType.clientRequest}`} >
                Zahtevi klijenata
            </Menu.Item>


            <Menu.Item
                // disabled={anyTypeClientGoalsLoading}
                as={Link}
                active={selectedItem === clientGoalNavbarIdFromType(ClientGoalType.payment)}
                to={`/${currentTraffic?.id}/clientGoals?type=${ClientGoalType.payment}`} >
                Naplate
            </Menu.Item>

            <Menu.Item
                // disabled={anyTypeClientGoalsLoading}
                as={Link}
                active={selectedItem === 'employeeGoals-problems'}
                to={`/${currentTraffic?.id}/employeeGoals?type=${EmployeeGoalType.problem}`} >
                Uočeni problemi
            </Menu.Item>
            
            <Menu.Item
                as={Link}
                to={`/${currentTraffic?.id}/clientTours`} >
                Kalendar obilazaka
            </Menu.Item>

            <Menu.Item
                as={NavLink}
                to={`/${currentTraffic?.id}/commentsSection`} >
                Komentari
            </Menu.Item>
        </>)
}

export default observer(ScheduleSectionMenu)
