import React, {FunctionComponent, useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {Button, Header, Icon, Item, Label, Message, Popup, Progress, Segment, SemanticCOLORS} from "semantic-ui-react";
import Table from "../../../core/common/tables/Table";
import {Cell, FilterProps} from "react-table";
import {Link} from "react-router-dom";
import {BillCreationType, IBill} from "../../../core/models/bills";
import {formatRsd} from "../../../core/common/util/format";
import {Types} from "../../../core/common/options/bill";
import moment from 'moment'
import ClientDetailedCell from "../../clients/list/components/ClientDetailedCell";
import {IClient} from "../../../core/models/clients";
import {ClientDetailedFilter} from "../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import {DateFilter} from "../../../core/common/tables/filters/date/DateFilter";
import SumTableHeader from "../../../core/common/tables/headers/SumTableHeader";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";
import UsersTableBasicCell from "../../users/list/UsersTableBasicCell";
import {BillTypeFilter} from "../../../core/common/tables/filters/bills/type/BillTypeFilter";
import MultiselectFilter from "../../../core/common/tables/filters/multiselect/MultiselectFilter";
import {FcInfo} from "react-icons/all";

interface IProps {
    hideTraffic?: boolean
    hideClient?: boolean
    gratis?: 'excluded' | 'only'
    trackingId?: string,
    smallCreator?: boolean
}


const BillsList: FunctionComponent<IProps> = ({hideTraffic, hideClient, gratis, trackingId, smallCreator}) => {
    const rootStore = useContext(RootStoreContext)
    const {
        billsArray: bills,
        billsArrayGratisExcluded: billsGratisExcluded,
        billsArrayGratisOnly: billsGratisOnly,
        getBillDoc,
        loadingBillId,
        deletingBillId,
        deleting,
        deleteBill
    } = rootStore.billsStore
    const {loadClientCategoriesIfNull, categories} = rootStore.clientsStore
    const {regionsArray: regions, loadRegionsIfNull} = rootStore.regionsStore
    const {openModal, closeModal} = rootStore.modalStore
    const {
        currentTraffic,
        trafficsArray: traffics // this was loaded when the app is starting 
    } = rootStore.trafficsStore
    const {isEmployee} = rootStore.userStore

    const data = gratis === 'excluded' ? billsGratisExcluded :
        gratis === 'only' ? billsGratisOnly :
            bills

    useEffect(() => {
        loadRegionsIfNull()
        loadClientCategoriesIfNull()
    }, [
        loadRegionsIfNull,
        loadClientCategoriesIfNull
    ])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Šifra',
                accessor: 'idString',
                Cell: ({row: {original: bill}}: Cell<IBill>) => (
                    <Button basic loading={bill.id === loadingBillId}
                            onClick={() => getBillDoc(bill.id)}
                            content={bill.idString}
                    />)
            },
            {
                id: 'traffic',
                accessor: 'traffic.id',
                Header: 'Promet',
                Cell: ({row: {original: bill}}: Cell<IBill>) => <span>{bill.traffic.name}</span>,
                hide: hideTraffic,
                Filter: (d: FilterProps<any>) => MultiselectFilter(d, traffics.map(traffic => ({
                    key: traffic.id,
                    value: traffic.id,
                    text: traffic.name,
                })), 'Promet'),
                filter: 'multiselectFilter'
            },
            {
                Header: 'Tip',
                Cell: ({row: {original: bill}}: Cell<IBill>) =>
                    <>
                        {bill.isGratis &&
                        <Label ribbon color='orange' content='GRATIS'/>}
                        {bill.isOnSale &&
                        <Label ribbon color='red' content={`AP: ${bill.onSaleNote}`}/>}
                        <p>
                            {bill.type === Types.Specification 
                                ? 'Specifikacija' : bill.type === Types.Invoice 
                                    ? 'Faktura' : 'Nepoznat tip'}
                            {bill.creationType === BillCreationType.Manual &&
                            <Popup trigger={<FcInfo size={16}/>}>Ručno urađen račun</Popup>}
                        </p>
                    </>,
                accessor: 'type',
                Filter: BillTypeFilter,
                filter: 'billTypeFilter'
            },
            {
                id: 'client',
                Header: 'Klijent',
                accessor: (d: IClient) => d.name,
                hide: hideClient,
                Filter: (d: any) => ClientDetailedFilter(d, regions!, categories!),
                filter: 'clientDetailedFilter',
                Cell: ({row: {original: bill}}: Cell<IBill>) => <ClientDetailedCell client={bill.client}/>
            },
            {
                accessor: 'value',
                Header: (row: any) => <SumTableHeader row={row} type={'money'} name={'Vrednost'} accessor={'value'}/>,
                Cell: ({row: {original: bill}}: Cell<IBill>) => <span>{formatRsd(bill.value)}</span>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                Header: 'Popust',
                accessor: (d: IBill) => d.discountRate,
                Cell: ({row: {original: bill}}: Cell<IBill>) => <span>{bill.discountRate}%</span>,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {
                Header: 'Napomena',
                accessor: (d: IBill) => d.note
            },
            {
                Header: 'Plaćeno',
                Cell: ({row: {original: bill}}: Cell<IBill>) => {

                    if (bill.isGratis) {
                        return <div>N/A</div>
                    }

                    let paidedInstallmentsCount = 0
                    let progressColor: SemanticCOLORS = 'blue'

                    bill.billInstallments.forEach(installment => {
                        if (installment.paid) {
                            paidedInstallmentsCount++
                        } else {
                            if (moment(installment.date).isBefore(Date.now(), 'day'))
                                progressColor = 'red'
                        }
                    })

                    if (bill.installmentsCount === paidedInstallmentsCount) {
                        progressColor = 'green'
                    }

                    return <Progress
                        color={progressColor}
                        value={paidedInstallmentsCount}
                        total={bill.installmentsCount}
                        progress='ratio'/>

                }
            },
            {
                id: 'date',
                Header: 'Datum',
                accessor: (d: IBill) => d.date,
                Cell: ({row: {original: bill}}: Cell<IBill>) => moment(bill.date).format('l'),
                Filter: DateFilter,
                filter: 'dateFilter',
                // sortType: 'date'
            },
            {
                id: 'payday',
                Header: 'Dospeće',
                accessor: (d: IBill) => d.payday,
                Cell: (cell: Cell<IBill>) => {
                    const {row: {original: bill}} = cell

                    const payday = moment(bill.payday)
                    if (bill.isGratis)
                        return <>N/A</>

                    return <span>{payday.format('l')}</span>
                },
                Filter: DateFilter,
                filter: 'dateFilter'
            },
            {
                Header: 'Izdao',
                accessor: 'user.displayName',
                Cell: ({row: {original: bill}}: Cell<IBill>) => smallCreator ?
                    <Item.Image as={Link} to={`/${currentTraffic?.id}/profile/${bill.user.username}`} size='mini' avatar
                                src={bill.user.image || '/assets/user.png'}/> :
                    <UsersTableBasicCell user={bill.user}/>
            },
            {
                Header: 'Kontrole',
                Cell: ({row: {original: bill}}: Cell<IBill>) => (
                    <Button.Group fluid>
                        {isEmployee &&
                        <>
                            <Button as={Link} to={`/${currentTraffic?.id}/bills/manage/${bill.id}/editCart`}
                                    color='teal'
                                    icon>
                                <Icon name='edit'/>
                            </Button>
                            <Button onClick={() => openModal(
                                <Segment basic clearing>
                                    <Header textAlign='center' as='h2'>
                                        Brisanje računa
                                        <Icon color='red' name='warning circle'/>
                                    </Header>
                                    <p style={{textAlign: 'center'}}>
                                        Da li zaista želiš da obrišeš račun <strong>{bill.idString} ?</strong>
                                    </p>
                                    <Button color='red' floated='right' onClick={(event: any) => {
                                        event.target.className += ' loading'
                                        deleteBill(bill.id)
                                            .then(() => closeModal())
                                    }}>
                                        <Icon name='checkmark'/> Da
                                    </Button>
                                    <Button onClick={() => closeModal()} floated='right'>
                                        <Icon name='remove'/> Ne
                                    </Button>
                                </Segment>, 'small'
                            )} icon='trash alternate' color='red' loading={(deletingBillId === bill.id) && deleting}/>
                        </>}
                    </Button.Group>
                )
            },

        ],
        [
            getBillDoc,
            loadingBillId,
            closeModal,
            deleteBill,
            deleting,
            deletingBillId,
            openModal,
            currentTraffic,
            hideClient,
            hideTraffic,
            regions,
            categories,
            isEmployee,
            smallCreator,
            traffics
        ]
    )

    if (data.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje računi po zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    return (

        <Table
            trackingId={trackingId}
            columns={columns}
            data={gratis === 'excluded' ? billsGratisExcluded :
                gratis === 'only' ? billsGratisOnly :
                    bills}/>

    );
};

export default observer(BillsList)
