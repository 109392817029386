import React, {useContext, useMemo} from "react";
import {Cell, FilterProps} from "react-table";
import {IClient, IClientFilter} from "../../../core/models/clients";
import ClientDetailedCell from "./components/ClientDetailedCell";
import {ClientDetailedFilter} from "../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import {clientDetailedFilter} from "../../../core/common/tables/filters/clients/logic/clientDetailedFilter";
import ClientTableLocationCell from "./components/ClientTableLocationCell";
import ClientTableContactCell from "./components/ClientTableContactCell";
import ClientTableNotesCell from "./components/ClientTableNotesCell";
import UserBasicCell from "../../user/table/UserBasicCell";
import MultiselectFilter from "../../../core/common/tables/filters/multiselect/MultiselectFilter";
import SumTableHeader from "../../../core/common/tables/headers/SumTableHeader";
import {formatRsd} from "../../../core/common/util/format";
import {Button, Header, Icon, Segment} from "semantic-ui-react";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";
import {numberToStringToFixedOrInt} from "../../../core/common/util/string";
import ClientProgress from "../commom/ClientProgress";
import ClientDetailsGenerateCardModal from "../details/header/ClientDetailsGenerateCardModal";
import {Link} from "react-router-dom";
import {RootStoreContext} from "../../../core/stores/rootStore";

export const useClientListColumns = ({
                                         hideRegion,
                                         hideBalance,
                                         hideProgress
                                     }: { hideRegion?: boolean, hideBalance?: boolean, hideProgress?: boolean }) => {
    const rootStore = useContext(RootStoreContext);
    const {
        deleting,
        deleteClient,
        deletingId,
        categories
    } = rootStore.clientsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {openModal, closeModal} = rootStore.modalStore
    const {regionsArray: regions} = rootStore.regionsStore
    const {employeesArray: employees} = rootStore.usersStore

    const columns = useMemo(
        () => [
            {
                Header: 'Naziv',
                Cell: ({row: {original: client}}: Cell<IClient>) => (<ClientDetailedCell client={client}/>),
                id: 'client',
                accessor: (d: IClient) => d.name,
                Filter: (d: any) => ClientDetailedFilter(d, regions!, categories!),
                filter: (rows: any, id: any, filterValue: IClientFilter) => clientDetailedFilter(rows, id, filterValue, true),
            },
            {
                Header: 'Lokacija',
                accessor: 'city',
                Cell: (cell: Cell<IClient>) => (
                    <ClientTableLocationCell hideRegion={hideRegion} client={cell.row.original}/>)
            },
            {
                Header: 'Kontakt',
                Cell: (cell: Cell<IClient>) => (<ClientTableContactCell client={cell.row.original}/>),
                accessor: (d: IClient) => d.contactInfo
            },
            {
                Header: 'Napomena',
                Cell: (cell: Cell<IClient>) => (<ClientTableNotesCell hidePublic client={cell.row.original}/>)
            },
            {
                Header: 'Račun',
                accessor: 'currentAccount'
            },
            {
                accessor: (d: any) => d.agent?.id,
                Header: 'Agent',
                Cell: ({row: {original: client}}: Cell<IClient>) => client.agent ?
                    <UserBasicCell
                        avatar
                        imageSize='mini'
                        textSize='tiny'
                        user={client.agent}/> :
                    'N/A',
                Filter: (d: FilterProps<any>) =>
                    MultiselectFilter(d, employees.map(user => ({
                            key: user.id,
                            value: user.id,
                            text: `${user.displayName} [${user.email}]`
                        })),
                        'Agent'),
                filter: 'multiselectFilter'
            },
            {
                id: 'financialState.balanceWithInitialState',
                Header: (row: any) =>
                    <SumTableHeader row={row} type={'money'} name={'Saldo'}
                                    accessor={'financialState.balanceWithInitialState'}/>,
                accessor: (d: IClient) => d.financialState?.balanceWithInitialState,
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) => (financialState?.balanceWithInitialState !== null)

                    ? (
                        <div>
                            {formatRsd(financialState?.balanceWithInitialState ?? NaN)}
                        </div>
                    ) : (<Icon name='cog' loading/>)
                ,
                hide: hideBalance,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            {

                accessor: (d: IClient) => {

                    const placed = d.financialState?.placedBaseValue
                    const scheduled = d.financialState?.scheduleValue

                    if (!placed || !scheduled) {
                        return 0
                    }

                    return placed / scheduled
                },
                id: 'financialState.scheduleValue',
                Header: (row: any) => {

                    const filteredRows: IClient[] = row.filteredRows.map((row: any) => row.original)

                    const sum = filteredRows.reduce((previousValue, currentValue) => {
                            const placed = currentValue.financialState?.placedBaseValue ?? 0
                            const scheduled = currentValue.financialState?.scheduleValue ?? 0

                            let percentage = 0

                            if (scheduled !== 0) {
                                percentage = placed / scheduled * 100
                                if (percentage > 100) {
                                    percentage = 100
                                }
                            }

                            return {
                                placed: previousValue.placed + (scheduled === 0 ? 0 : placed),
                                scheduled: previousValue.scheduled + (scheduled === 0 ? 0 : scheduled),
                                percentageSum: previousValue.percentageSum + (scheduled === 0 ? 0 : percentage),
                                withScheduleCount: previousValue.withScheduleCount + (scheduled === 0 ? 0 : 1)
                            }
                        },
                        {
                            placed: 0,
                            scheduled: 0,
                            percentageSum: 0,
                            withScheduleCount: 0
                        })

                    return <>
                        <Header sub>
                            Ostvarenje
                        </Header>
                        <strong>
                            {formatRsd(sum.placed)} / {formatRsd(sum.scheduled)} [{numberToStringToFixedOrInt(sum.percentageSum / sum.withScheduleCount, 2)}%]
                        </strong>
                    </>
                },
                Cell: ({row: {original: {financialState}}}: Cell<IClient>) => <ClientProgress
                    value={financialState?.placedBaseValue} total={financialState?.scheduleValue}/>,
                hide: hideProgress,
                Filter: NumberFilter,
                filter: 'numberFilter',
                sortType: 'numeric'
            },
            // {
            //     id: 'rank',
            //     accessor: 'id',
            //     hide: hideRank,
            //     Header: 'Rang',
            //     Cell: ({row: {original: client }}: Cell<IClient>) => <Header 
            //         color={client.type === 'Aktivan' ? clientStatusToColorName(client.status!) : 'grey'} 
            //         as='h4' 
            //         content={`${alphabet[getClientRankByStatusAndType(client)]
            //             .toUpperCase()}${client.financialState?.placedBaseValue}`} />,
            //     Filter: <></>,
            //     sortType: 'clientRank',
            // },
            {
                Header: 'Kontrole',
                Cell: ({row: {original: client}}: Cell<IClient>) => (
                    <Button.Group>
                        <ClientDetailsGenerateCardModal client={client}/>
                        <Button as={Link} to={`/${currentTraffic?.id}/clients/manage/${client.id}/edit`} icon='edit'
                                color='yellow'/>
                        <Button onClick={() => openModal(
                            <Segment basic clearing>
                                <Header textAlign='center' as='h2'>
                                    Brisanje klijenta
                                    <Icon color='red' name='warning circle'/>
                                </Header>
                                <p style={{textAlign: 'center'}}>
                                    Da li zaista želiš da obrišeš klijenta <strong>{client.name} ?</strong>
                                </p>
                                <Button color='red' floated='right' onClick={(event: any, data) => {
                                    event.target.className += ' loading'
                                    deleteClient(client.id!)
                                        .then(() => closeModal())
                                }}>
                                    <Icon name='checkmark'/> Da
                                </Button>
                                <Button onClick={() => closeModal()} floated='right'>
                                    <Icon name='remove'/> Ne
                                </Button>
                            </Segment>, 'small'
                        )} icon='trash alternate' color='red' loading={(deletingId === client.id) && deleting}/>
                    </Button.Group>)
            },

        ],
        [
            closeModal,
            deleteClient,
            deleting,
            openModal,
            deletingId,
            hideRegion,
            currentTraffic,
            hideBalance,
            hideProgress,
            categories,
            regions,
            // hideRank,
            employees
        ]
    )
    
    return {
        columns
    }
}