import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {combineValidators, isRequired, isRequiredIf} from "revalidate";
import {RouteComponentProps} from "react-router";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {v4 as uuid} from "uuid";
import {Button, Form, Grid, Segment, Select} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import {FORM_ERROR} from "final-form";
import SelectStringInput from "../../../core/common/form/SelectStringInput";
import TextInput from "../../../core/common/form/TextInput";
import ErrorMessage from "../../../core/common/form/ErrorMessage";
import {observer} from "mobx-react-lite";
import {EmployeeGoalFormValues, EmployeeGoalType, IEmployeeGoalFormValues} from "../../../core/models/employeeGoals";
import DateInput from "../../../core/common/form/DateInput";
import {employeeGoalsColorsOptions} from "../../../core/common/options/employeeGoals";
import {useLocation} from 'react-router-dom';
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import arrayMutators from 'final-form-arrays'
import {FieldArray} from "react-final-form-arrays";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import TextAreaInput from "../../../core/common/form/TextAreaInput";


interface IProps {

}


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
    color: isRequired({message: 'Boja je obavezna'}),
    category: isRequired({message: 'Kategorija je obavezna'}),
    //@ts-ignore
    mainParticipant: isRequiredIf((values: IEmployeeGoalFormValues) => values.participants.length > 1)({message: 'Izaberi glavnog učesnika'}),
})

interface DetailParams {
    id: string;
}

interface IParams {
    type?: EmployeeGoalType
}


const EmployeeGoalForm: FunctionComponent<RouteComponentProps<DetailParams>> & IParams = ({match, history}) => {

    const rootStore = useContext(RootStoreContext)
    const {currentTraffic} = rootStore.trafficsStore
    const {
        loadEmployeeGoal,
        createEmployeeGoal,
        editEmployeeGoal,
        submitting,
        employeeGoalsCategories: categories,
        loadEmployeeGoalsCategories,
        loadingEmployeeGoalsCategories
    } = rootStore.employeeGoalsStore
    const {loadingUsers, loadUsers, employeesArray: employees} = rootStore.usersStore

    const [employeeGoal, setEmployeeGoal] = useState<IEmployeeGoalFormValues>(new EmployeeGoalFormValues())
    const [loading, setLoading] = useState(false)
    const [selectedParticipants, setSelectedParticipants] = useState<string[]>([])

    useNavbarSelectedItem(match.params.id ? '' : 'employeeGoalsCreate')

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery()
    const typeParam = query.get('type')
    const type: EmployeeGoalType = Object.values(EmployeeGoalType)?.includes(Number(typeParam))
        ? Number(typeParam)
        : EmployeeGoalType.classic

    useEffect(() => {
        loadEmployeeGoalsCategories(true)
        loadUsers(true)

        if (match.params.id) {
            setLoading(true)
            loadEmployeeGoal(match.params.id)
                .then(employeeGoal => {
                        setEmployeeGoal(new EmployeeGoalFormValues(employeeGoal))
                        setSelectedParticipants(employeeGoal?.participants.map(p => p.user.id) ?? [])
                    }
                )
                .finally(() => setLoading(false))

        } else {
            setEmployeeGoal(eg => ({
                ...eg,
                type
            }))
        }


    }, [
        match.params.id,
        loadEmployeeGoal,
        loadEmployeeGoalsCategories,
        loadUsers,
        typeParam,
        type
    ]);

    const handleFinalFormSubmit = (values: any) => {
        const employeeGoal: IEmployeeGoalFormValues = {
            ...values,
            date: values.date ? values.date : null,
            completedDate: values.completedDate ? values.completedDate : null
        }

        if (!employeeGoal.id) {
            let newEmployeeGoal = {
                ...employeeGoal,
                id: uuid()
            }

            return createEmployeeGoal(newEmployeeGoal)
                .then(() => {
                    history.push(`/${currentTraffic?.id}/employeeGoals?type=${type}`)
                })
        } else {
            return editEmployeeGoal(employeeGoal)
                .then(() => history.push(`/${currentTraffic?.id}/employeeGoals/${employeeGoal.id}`))
        }
    }

    return (
        <Grid centered>

            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Plan rada'
                        },
                        {
                            text: type === EmployeeGoalType.problem ? 'Uočeni problemi' :
                                type === EmployeeGoalType.private ? 'Privatne obaveze' :
                                    'Tekuće obaveze',
                            linkWithoutCurrentTrafficId: `employeeGoals?type=${type}`
                        },
                        {
                            text: (match.params.id ? 'Izmena ' : 'Dodavanje ') + (type === EmployeeGoalType.problem ? 'uočenog problema' :
                                type === EmployeeGoalType.private ? 'privatne obaveze' :
                                'tekuće obaveze'),
                            active: true
                        }
                    ]}
                />
            </Grid.Column>

            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm
                        onSubmit={(values: IEmployeeGoalFormValues) => handleFinalFormSubmit(values)
                            .catch(error => ({
                                [FORM_ERROR]: error
                            })
                        )}
                        mutators={{...arrayMutators}}
                        validate={validate}
                        initialValues={employeeGoal}
                        render={({
                                     handleSubmit,
                                     invalid,
                                     pristine,
                                     submitError,
                                     dirtySinceLastSubmit,
                                     form,
                                     values
                                 }) => (
                            <Form onSubmit={handleSubmit}
                                  loading={loading || loadingEmployeeGoalsCategories || loadingUsers}>

                                <Field
                                    value={typeParam}
                                    name='type'
                                    component='input'
                                    type='hidden'
                                />

                                <Form.Field>
                                    <label>Naziv</label>
                                    <Field
                                        name='name'
                                        value={employeeGoal.name}
                                        placeholder='Naziv'
                                        component={TextInput}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Kategorija</label>
                                    <Field
                                        name='category'
                                        placeholder='Kategorija'
                                        value={employeeGoal.category}
                                        component={TextInput}
                                        suggestions={categories?.filter(x => x.type === type).map(x => x.name)}
                                    />
                                </Form.Field>

                                <Form.Group>

                                    <Form.Field>
                                        <label>Datum</label>
                                        <Field
                                            component={DateInput}
                                            name='date'
                                            date={true}
                                            placeholder='Datum'
                                            value={employeeGoal.date}
                                        />

                                    </Form.Field>

                                    <Form.Field>
                                        <label>Datum završetka</label>
                                        <Field
                                            component={DateInput}
                                            name='completedDate'
                                            date={true}
                                            placeholder='Datum zavrsetka'
                                            value={employeeGoal.completedDate}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Field>
                                    <label>Boja</label>
                                    <Field
                                        name='color'
                                        placeholder='Boja'
                                        value={employeeGoal.color as string}
                                        options={employeeGoalsColorsOptions}
                                        component={SelectStringInput}/>
                                </Form.Field>

                                <Form.Field>
                                    <label>Učesnici</label>
                                    <FieldArray name='participants'>
                                        {({fields, meta}) => {

                                            return <Select
                                                value={selectedParticipants}
                                                multiple
                                                clearable
                                                onChange={((event, data) => {
                                                    setSelectedParticipants(data.value as string[])
                                                    form.change('participants', data.value as string[])

                                                    // todo little hack, try to do this in validator
                                                    const mainParticipantFromForm = form.getState().values.mainParticipant
                                                    const dataValues = data.value as string[]
                                                    if (mainParticipantFromForm && !dataValues.includes(mainParticipantFromForm)) {
                                                        form.change('mainParticipant', '')
                                                    }
                                                })}
                                                options={employees.map(user => ({
                                                    key: user.id,
                                                    value: user.id,
                                                    text: `${user.displayName} [${user.email}]`
                                                }))}
                                            />
                                        }
                                        }
                                    </FieldArray>
                                </Form.Field>

                                {selectedParticipants.length > 1 &&
                                <Form.Field>
                                    <label>Glavni učesnik</label>
                                    <Field
                                        name='mainParticipant'
                                        value={employeeGoal.mainParticipant}
                                        component={SelectStringInput}
                                        options={employees
                                            .filter(user => selectedParticipants.includes(user.id))
                                            .map(user => ({
                                                key: user.id,
                                                value: user.id,
                                                text: `${user.displayName} [${user.email}]`
                                            }))}
                                    />
                                </Form.Field>}

                                {!match.params.id &&
                                <Form.Field>
                                    <label>Inicijalni komentar</label>
                                    <Field
                                        name='initialCommentContent'
                                        component={TextAreaInput}
                                    />
                                </Form.Field>}

                                {(submitError && !dirtySinceLastSubmit) &&
                                <ErrorMessage error={submitError}/>}
                                <Button
                                    loading={submitting}
                                    disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                    floated='right'
                                    color='blue'
                                    type='submit'
                                    content={(!employeeGoal.id) ? 'Dodaj' : 'Izmeni'}
                                />
                                <Button
                                    onClick={employeeGoal.id ?
                                        () => history.push(`/${currentTraffic?.id}/employeeGoals/${employeeGoal.id}`) :
                                        () => history.push(`/${currentTraffic?.id}/employeeGoals`)}
                                    disabled={loading}
                                    floated='right'
                                    type='button'
                                    content='Odustani'/>
                            </Form>
                        )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(EmployeeGoalForm)