import {FilterProps} from "react-table";
import React from "react";
import {Dropdown, Input, Select} from "semantic-ui-react";
import {ClientFilter, IClientFilter} from "../../../../../models/clients";
import {IRegion} from "../../../../../models/region";
import LoadingComponent from "../../../../../layout/LoadingComponent";
import {stringPreview} from "../../../../util/string";
import {clientStatusOptions, clientTypesOptions} from "../../../../options/client";
import CompactFilterLayout from "../../common/CompactFilterLayout";

export const ClientDetailedFilter = ({
                                         column: {
                                             filterValue,
                                             preFilteredRows,
                                             filteredRows,
                                             setFilter,
                                             filter
                                         }
                                     }: FilterProps<IClientFilter>,
                                     regions: IRegion[],
                                     categories: string[]
) => {

    // const [showFilter, setShowFilter] = useState(false)

    if (regions === null || categories === null) return <LoadingComponent/>

    const renderLabel = (label: any) => ({
        color: 'blue',
        content: filterValue.regions.length > 3 ? stringPreview(label.text, 5) : label.text,
        size: 'mini'
    })


    return <CompactFilterLayout
        filterValue={filterValue}
        onClearFilterClick={() => setFilter(new ClientFilter())}>
        <Input
            fluid
            // value={filterValue.name}
            onChange={(event, data) => setFilter( {...filterValue, name: data.value})}
            placeholder='Naziv'
        />

        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={regions.map((region: IRegion) => ({
                key: region.id!,
                text: region.name.toString(),
                value: region.id!
            }))}
            value={filterValue.regions ?? []}
            renderLabel={renderLabel}
            placeholder='Region'
            onChange={(event, data) => setFilter({...filterValue, regions: data.value ?? []})}
        />

        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={categories.map((category: string) => ({
                key: category,
                text: category,
                value: category
            }))}
            value={filterValue.categories ?? []}
            renderLabel={renderLabel}
            placeholder='Kategorija'
            onChange={(event, data) => setFilter({...filterValue, categories: data.value ?? []})}
        />

        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={clientStatusOptions}
            value={filterValue.statusList ?? []}
            renderLabel={renderLabel}
            placeholder='Status'
            onChange={(event, data) => setFilter({...filterValue, statusList: data.value ?? []})}
        />
        <Dropdown
            clearable
            fluid
            button
            multiple
            search
            selection
            basic
            options={clientTypesOptions}
            value={filterValue.types ?? []}
            renderLabel={renderLabel}
            placeholder='Vrsta'
            onChange={(event, data) => setFilter({...filterValue, types: data.value ?? []})}
        />
        <Select
            fluid
            placeholder='Aktuelnost'
            options={[
                {key: 'included', value: '', text: 'Svi'},
                {key: 'only', value: 'only', text: 'Aktuelni'},
                {key: 'excluded', value: 'excluded', text: 'Ne-aktuelni'},
            ]}
            onChange={(event, data) => setFilter({...filterValue, focus: data.value})}
        />
    </CompactFilterLayout>
}