import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import Table from "../../../core/common/tables/Table";
import {IClientInitialState} from "../../../core/models/clientInitialStates";
import {Cell} from "react-table";
import ClientTableBasicCell from "../../clients/list/components/ClientTableBasicCell";
import {Button, Header, Icon, Label, Segment} from "semantic-ui-react";
import {formatRsd} from "../../../core/common/util/format";
import {Link} from "react-router-dom";
import {NumberFilter} from "../../../core/common/tables/filters/number/NumberFilter";
import {ClientDetailedFilter} from "../../../core/common/tables/filters/clients/ui/ClientDetailedFilter";
import {IClientActivity} from "../../../core/models/clientActivities";


interface IProps {
    trackingId?: string
}

const ClientInitialStatesList: FunctionComponent<IProps> = ({trackingId}) => {
  const rootStore = useContext(RootStoreContext)
    const { 
      clientInitialStatesArrayFilteredByYear: clientInitialStates,
        deletingId, 
        deleting,
        deleteClientInitialState
  } = rootStore.clientInitialStatesStore
    const { currentTraffic } = rootStore.trafficsStore
    const { openModal, closeModal } = rootStore.modalStore
    const { regionsArray: regions, loadRegionsIfNull } = rootStore.regionsStore
    const { categories, loadClientCategoriesIfNull } = rootStore.clientsStore
    
    useEffect(() => {
        loadClientCategoriesIfNull()
        loadRegionsIfNull()
    }, [
        loadClientCategoriesIfNull,
        loadRegionsIfNull
    ])
    
    const columns = useMemo(() => [
        {
            id: 'client',
            Header: 'Klijent',
            accessor: (d: IClientActivity) => d.client.name,
            Cell: ({row: { original: clientInitialState }}: Cell<IClientInitialState>) => <ClientTableBasicCell client={clientInitialState.client} />,
            Filter: (d: any) =>  ClientDetailedFilter(d, regions!, categories!),
            filter: 'clientDetailedFilter',
        },
        {
            accessor: (d: IClientInitialState) => Number(d.year),
            Header: 'Godina',
            Cell: ({row: { original: clientInitialState }}: Cell<IClientInitialState>) => <Label basic content={clientInitialState.year} />,
            Filter: <></>
        },
        {
            accessor: 'value',
            Header: (row: any) => {
                let sum = 0
                row.data.forEach((client: IClientInitialState) => {
                    sum += client.value ?? NaN
                })

                return (
                    <div>
                        <Header color='teal' sub>Iznos</Header>
                        <span>Suma: {formatRsd(sum)}</span>
                    </div>
                )
            },
            Cell: ({row: { original: clientInitialState }}: Cell<IClientInitialState>) => <>{formatRsd(clientInitialState.value)} </>,
            Filter: NumberFilter,
            filter: 'numberFilter'
        },
        {
            Header: 'Kontrole',
            Cell: ({row: {original: clientInitialState}}: Cell<IClientInitialState>) => (
                <Button.Group>
                    <Button as={Link} to={`/${currentTraffic?.id}/clientInitialStates/manage/${clientInitialState.id}/edit`} icon='edit' color='yellow'/>
                    <Button onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje početnog stanja
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš početno stanjae iz {clientInitialState.year} klijenta <strong>{clientInitialState.client.name} ?</strong>
                            </p>
                            <Button color='red' floated='right' onClick={(event: any, data) => {
                                event.target.className += ' loading'
                                deleteClientInitialState(clientInitialState.id!)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} icon='trash alternate' color='red' loading={(deletingId === clientInitialState.id) && deleting}/>
                </Button.Group>)
        }
    ], [deleting, openModal, closeModal, deletingId, deleteClientInitialState, currentTraffic, regions, categories])
    
  return (
      <Table
          trackingId={trackingId}
          columns={columns}
          data={clientInitialStates}
      /> );
};

export default observer(ClientInitialStatesList)
