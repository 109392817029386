import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {combineValidators, isRequired, isRequiredIf} from "revalidate";
import {RouteComponentProps} from "react-router";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {v4 as uuid} from "uuid";
import {Button, Form, Grid, Segment, Select} from "semantic-ui-react";
import {Field, Form as FinalForm} from "react-final-form";
import {FORM_ERROR} from "final-form";
import SelectStringInput from "../../../core/common/form/SelectStringInput";
import TextInput from "../../../core/common/form/TextInput";
import ErrorMessage from "../../../core/common/form/ErrorMessage";
import {observer} from "mobx-react-lite";
import {ClientGoalFormValues, ClientGoalType, IClientGoalFormValues} from "../../../core/models/clientGoals";
import DateInput from "../../../core/common/form/DateInput";
import {clientGoalsColorsOptions} from "../../../core/common/options/clientGoals";
import {useLocation} from 'react-router-dom';
import {useNavbarSelectedItem} from "../../nav/utils/index.js";
import arrayMutators from 'final-form-arrays'
import {FieldArray} from "react-final-form-arrays";
import BreadcrumbNavigation from "../../nav/BreadcrumbNavigation";
import TextAreaInput from "../../../core/common/form/TextAreaInput";


interface IProps {

}


const validate = combineValidators({
    name: isRequired({message: 'Naziv je obavezan'}),
    clientId: isRequired({message: 'Klijent je obavezan'}),
    color: isRequired({message: 'Boja je obavezna'}),
    category: isRequired({message: 'Kategorija je obavezna'}),
    //@ts-ignore
    mainParticipant: isRequiredIf((values: IClientGoalFormValues) => values.participants.length > 1)({message: 'Izaberi glavnog učesnika'}),
})

interface DetailParams {
    id: string;
}

interface IParams {
    clientId?: string,
    type?: ClientGoalType
}

const breadcrumbListText = (type: ClientGoalType): string => {
    switch (type) {
        case ClientGoalType.payment:
            return 'Naplate'
        case ClientGoalType.clientRequest:
            return 'Zahtevi klijenata'
        case ClientGoalType.classic:
            return 'Ciljevi'
    }
}

const breadcrumbActiveSuffix = (type: ClientGoalType): string => {
    switch (type) {
        case ClientGoalType.payment: 
            return 'naplate'
        case ClientGoalType.clientRequest:
            return 'zahteva klijenta'
        case ClientGoalType.classic:
            return 'cilja'
    }
}

const ClientGoalForm: FunctionComponent<RouteComponentProps<DetailParams>> & IParams = ({match, history}) => {

    const rootStore = useContext(RootStoreContext)
    const {loadClients, clientsArray: clients, loading: loadingClients} = rootStore.clientsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {
        loadClientGoal,
        createClientGoal,
        editClientGoal,
        submitting,
        clientGoalsCategories: categories,
        loadClientGoalsCategories,
        loadingClientGoalsCategories
    } = rootStore.clientGoalsStore
    const {loadingUsers, loadUsers, employeesArray: employees} = rootStore.usersStore

    const [clientGoal, setClientGoal] = useState<IClientGoalFormValues>(new ClientGoalFormValues())
    const [loading, setLoading] = useState(false)
    const [selectedParticipants, setSelectedParticipants] = useState<string[]>([])

    useNavbarSelectedItem(match.params.id ? '' : 'clientGoalsCreate')

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery()
    const clientIdQueryParam = query.get('clientId')
    const typeParam = query.get('type')
    const type: ClientGoalType = Object.values(ClientGoalType)?.includes(Number(typeParam))
        ? Number(typeParam)
        : ClientGoalType.classic
    
    useEffect(() => {
        loadClients()
        loadClientGoalsCategories(true)
        loadUsers(true)

        if (match.params.id) {
            setLoading(true)
            loadClientGoal(match.params.id)
                .then(clientGoal => {
                        setClientGoal(new ClientGoalFormValues(clientGoal))
                        setSelectedParticipants(clientGoal?.participants.map(p => p.user.id) ?? [])
                    }
                )
                .finally(() => setLoading(false))

        } else {
            if (clientIdQueryParam) {
                setClientGoal(c => ({...c, clientId: clientIdQueryParam}))
            }

            setClientGoal(c => ({
                ...c,
                type
            }))
        }


    }, [
        match.params.id,
        loadClients,
        loadClientGoal,
        clientIdQueryParam,
        typeParam,
        loadClientGoalsCategories,
        loadUsers,
        type
    ]);

    const handleFinalFormSubmit = (values: any) => {
        const clientGoal: IClientGoalFormValues = {
            ...values,
            date: values.date ? values.date : null,
            completedDate: values.completedDate ? values.completedDate : null
        }

        if (!clientGoal.id) {
            let newClientGoal = {
                ...clientGoal,
                id: uuid()
            }

            return createClientGoal(newClientGoal)
                .then(() => history
                    .push(`/${currentTraffic?.id}/clientGoals?type=${type}`))
        } else {
            return editClientGoal(clientGoal)
                .then(() => history
                    .push(`/${currentTraffic?.id}/clientGoals/${clientGoal.id}`))
        }
    }

    return (
        <Grid centered>

            <Grid.Column width={16}>
                <BreadcrumbNavigation
                    items={[
                        {
                            text: 'Plan rada'
                        },
                        {
                            text: breadcrumbListText(type),
                            linkWithoutCurrentTrafficId: `clientGoals?type=${type}`
                        },
                        {
                            active: true,
                            text: (match.params.id ? 'Izmena ' : 'Dodavanje ') + breadcrumbActiveSuffix(type)
                        }
                    ]}
                />
            </Grid.Column>

            <Grid.Column computer={8} mobile={16} tablet={16}>
                <Segment clearing color='blue'>
                    <FinalForm
                        onSubmit={(values: IClientGoalFormValues) => handleFinalFormSubmit(values).catch(error => ({
                                [FORM_ERROR]: error
                            })
                        )}
                        mutators={{...arrayMutators}}
                        validate={validate}
                        initialValues={clientGoal}
                        render={({
                                     handleSubmit,
                                     invalid,
                                     pristine,
                                     submitError,
                                     dirtySinceLastSubmit,
                                     form,
                                     values
                                 }) => (
                            <Form onSubmit={handleSubmit}
                                  loading={loading || loadingClients || loadingClientGoalsCategories || loadingUsers}>

                                <Field
                                    value={typeParam}
                                    name='type'
                                    component='input'
                                    type='hidden'
                                />

                                <Form.Field>
                                    <label>Naziv</label>
                                    <Field
                                        name='name'
                                        value={clientGoal.name}
                                        placeholder='Naziv'
                                        component={TextInput}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Kategorija</label>
                                    <Field
                                        name='category'
                                        placeholder='Kategorija'
                                        value={clientGoal.category}
                                        component={TextInput}
                                        suggestions={categories?.filter(x => x.type === type).map(x => x.name)}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Klijent</label>
                                    <Field
                                        search
                                        name='clientId'
                                        placeholder='Klijent'
                                        value={clientGoal.clientId}
                                        options={clients.map(client => ({
                                            key: client.id,
                                            value: client.id,
                                            text: client.name
                                        }))}
                                        component={SelectStringInput}/>
                                </Form.Field>
                                <Form.Group>

                                    <Form.Field>
                                        <label>Datum</label>
                                        <Field
                                            component={DateInput}
                                            name='date'
                                            date={true}
                                            placeholder='Datum'
                                            value={clientGoal.date}
                                        />

                                    </Form.Field>

                                    <Form.Field>
                                        <label>Datum završetka</label>
                                        <Field
                                            component={DateInput}
                                            name='completedDate'
                                            date={true}
                                            placeholder='Datum zavrsetka'
                                            value={clientGoal.completedDate}
                                        />
                                    </Form.Field>
                                </Form.Group>

                                <Form.Field>
                                    <label>Boja</label>
                                    <Field
                                        name='color'
                                        placeholder='Boja'
                                        value={clientGoal.color as string}
                                        options={clientGoalsColorsOptions}
                                        component={SelectStringInput}/>
                                </Form.Field>

                                <Form.Field>
                                    <label>Učesnici</label>
                                    <FieldArray name='participants'>
                                        {({fields, meta}) => {

                                            return <Select
                                                value={selectedParticipants}
                                                multiple
                                                clearable
                                                onChange={((event, data) => {
                                                    setSelectedParticipants(data.value as string[])
                                                    form.change('participants', data.value as string[])

                                                    // todo little hack, try to do this in validator
                                                    const mainParticipantFromForm = form.getState().values.mainParticipant
                                                    const dataValues = data.value as string[]
                                                    if (mainParticipantFromForm && !dataValues.includes(mainParticipantFromForm)) {
                                                        form.change('mainParticipant', '')
                                                    }
                                                })}
                                                options={employees.map(user => ({
                                                    key: user.id,
                                                    value: user.id,
                                                    text: `${user.displayName} [${user.email}]`
                                                }))}
                                            />
                                        }
                                        }
                                    </FieldArray>
                                </Form.Field>

                                {selectedParticipants.length > 1 &&
                                <Form.Field>
                                    <label>Glavni učesnik</label>
                                    <Field
                                        name='mainParticipant'
                                        value={clientGoal.mainParticipant}
                                        component={SelectStringInput}
                                        options={employees
                                            .filter(user => selectedParticipants.includes(user.id))
                                            .map(user => ({
                                                key: user.id,
                                                value: user.id,
                                                text: `${user.displayName} [${user.email}]`
                                            }))}
                                    />
                                </Form.Field>}

                                {!match.params.id &&
                                <Form.Field>
                                    <label>Inicijalni komentar</label>
                                    <Field
                                        name='initialCommentContent'
                                        component={TextAreaInput}
                                    />
                                </Form.Field>}


                                {(submitError && !dirtySinceLastSubmit) &&
                                <ErrorMessage error={submitError}/>}
                                <Button
                                    loading={submitting}
                                    disabled={loading || (invalid && !dirtySinceLastSubmit) || pristine}
                                    floated='right'
                                    color='blue'
                                    type='submit'
                                    content={(!clientGoal.id) ? 'Dodaj' : 'Izmeni'}
                                />
                                <Button
                                    onClick={clientGoal.id ?
                                        () => history.push(`/${currentTraffic?.id}/clientGoals/${clientGoal.id}`) :
                                        () => history.push(`/${currentTraffic?.id}/clientGoals?type=${clientGoal.type}`)}
                                    disabled={loading || loadingClients}
                                    floated='right'
                                    type='button'
                                    content='Odustani'/>
                            </Form>
                        )}
                    />
                </Segment>
            </Grid.Column>
        </Grid>
    );
};

export default observer(ClientGoalForm)