import React, {FunctionComponent, useContext, useEffect, useMemo} from 'react';
import Table from "../../../core/common/tables/Table";
import {observer} from "mobx-react-lite";
import {RootStoreContext} from "../../../core/stores/rootStore";
import {EmployeeGoalType, IEmployeeGoal} from "../../../core/models/employeeGoals";
import {Cell, FilterProps} from "react-table";
import {Button, Header, Icon, Message, Segment} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {EmployeeGoalFilter} from "../../../core/common/tables/filters/employeeGoals/EmployeeGoalFilter";
import EmployeeGoalIdentity from "../commom/EmployeeGoalIdentity";
import EmployeeGoalParticipantsIdentity from "../commom/EmployeeGoalParticipantsIdentity";
import MultiselectFilter from "../../../core/common/tables/filters/multiselect/MultiselectFilter";
import EmployeeGoalDatesCell from "./EmployeeGoalDatesCell";
import EmployeeGoalCommentCell from "./EmployeeGoalCommentCell";
import EmployeeGoalDocumentsCell from "./EmployeeGoalDocumentsCell";


interface IProps {
    trackingId?: string,
    hideMetFilter?: boolean // todo setiti se zasto sam ovo stavio,
    type: EmployeeGoalType
}


const EmployeeGoalsList: FunctionComponent<IProps> = ({
                                                          trackingId,
                                                          hideMetFilter,
                                                          type
                                                      }) => {
    const rootStore = useContext(RootStoreContext)
    const {
        deleteEmployeeGoal,
        loadEmployeeGoalsCategories,
        employeeGoalsFilteredClassic,
        employeeGoalsFilteredProblem,
        employeeGoalsFilteredPrivate,
        categoryNamesFilterByType
    } = rootStore.employeeGoalsStore
    const {currentTraffic} = rootStore.trafficsStore
    const {openModal, closeModal} = rootStore.modalStore
    const {isEmployee} = rootStore.userStore
    const {loadUsers, employeesArray: employees} = rootStore.usersStore

    useEffect(() => {
        loadEmployeeGoalsCategories(true)
        loadUsers(true) // for participants
    }, [
        loadEmployeeGoalsCategories,
        loadUsers
    ])

    const filteredData = type === EmployeeGoalType.problem
        ? employeeGoalsFilteredProblem :
        type === EmployeeGoalType.private
            ? employeeGoalsFilteredPrivate :
            employeeGoalsFilteredClassic

    const columns = useMemo(() => [
        {
            id: 'employeeGoal',
            Header: 'Tekuća obaveza',
            accessor: 'name',
            Cell: ({row: {original: employeeGoal}}: Cell<IEmployeeGoal>) => <EmployeeGoalIdentity
                employeeGoal={employeeGoal}/>,
            filter: 'employeeGoalFilter',
            Filter: (d: any) => EmployeeGoalFilter(d, categoryNamesFilterByType(type)),
        },
        {
            accessor: (d: IEmployeeGoal) => d.participants.map(participant => participant.user.id),
            Header: 'Učesnici',
            Cell: ({row: {original: employeeGoal}}: Cell<IEmployeeGoal>) =>
                <EmployeeGoalParticipantsIdentity employeeGoal={employeeGoal}/>,
            Filter: (d: FilterProps<any>) => MultiselectFilter(d, employees.map(user => ({
                key: user.id,
                value: user.id,
                text: `${user.displayName} [${user.email}]`
            })), 'Učesnik'),
            filter: 'multiselectFilter',
            disableSortBy: true
        },
        {
            id: 'dates',
            Header: 'Datumi',
            // accessor: 'date',
            Cell: ({row: {original: employeeGoal}}: Cell<IEmployeeGoal>) => <EmployeeGoalDatesCell
                employeeGoal={employeeGoal}/>,
            // Filter: DateFilter,
            // filter: 'dateFilter',
        },
        {
            accessor: 'id',
            Header: 'Komentari',
            Cell: ({row: {original: employeeGoal}}: Cell<IEmployeeGoal>) => <EmployeeGoalCommentCell
                employeeGoal={employeeGoal}/>,
            sortType: 'employeeGoalsLastComment'
        },
        {
            Header: 'Poslednji dokument',
            Cell: ({row: {original: employeeGoal}}: Cell<IEmployeeGoal>) => <EmployeeGoalDocumentsCell
                employeeGoal={employeeGoal}/>
        },
        {
            Header: 'Kontrole',
            id: 'controls',
            hide: !isEmployee,
            Cell: ({row: {original: employeeGoal}}: Cell<IEmployeeGoal>) => (
                <Button.Group>
                    <Button as={Link} to={`/${currentTraffic?.id}/employeeGoals/manage/${employeeGoal.id}/edit`}
                            icon='edit'
                            color='yellow'/>
                    <Button onClick={() => openModal(
                        <Segment basic clearing>
                            <Header textAlign='center' as='h2'>
                                Brisanje {employeeGoal.type === EmployeeGoalType.problem ? 'uočenog problema' : 'tekuće obaveze'}
                                <Icon color='red' name='warning circle'/>
                            </Header>
                            <p style={{textAlign: 'center'}}>
                                Da li zaista želiš da obrišeš
                                {employeeGoal.type === EmployeeGoalType.problem ? 'uočeni problem' : 'tekuću obavezu'}
                                <strong>{employeeGoal.name}</strong>?
                            </p>
                            <Button color='red' floated='right' onClick={(event: any, data) => {
                                event.target.className += ' loading'
                                deleteEmployeeGoal(employeeGoal.id!)
                                    .then(() => closeModal())
                            }}>
                                <Icon name='checkmark'/> Da
                            </Button>
                            <Button onClick={() => closeModal()} floated='right'>
                                <Icon name='remove'/> Ne
                            </Button>
                        </Segment>, 'small'
                    )} icon='trash alternate' color='red' loading={employeeGoal.flags?.deleting}/>
                </Button.Group>)
        }
    ], [
        closeModal,
        openModal,
        currentTraffic,
        deleteEmployeeGoal,
        isEmployee,
        // employeeGoalsCategories,
        employees,
        categoryNamesFilterByType,
        type
    ])

    if (filteredData.length === 0)
        return <div style={{marginTop: 50}}>
            <Message warning>
                <Message.Header>Ne postoje {type === EmployeeGoalType.problem ? 'uočeni problemi' : 'tekuće obaveze'} po
                    zadatom kriterijumu</Message.Header>
                <p>Pokušaj da osvežiš stranicu.</p>
            </Message>
        </div>

    console.log(employeeGoalsFilteredPrivate)

    return <Table
        trackingId={trackingId}
        columns={columns}
        data={filteredData}/>
}

export default observer(EmployeeGoalsList)
