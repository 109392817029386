import React, {Fragment, FunctionComponent, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {
    Route,
    withRouter,
    RouteComponentProps
} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {Dimmer, Loader} from "semantic-ui-react";
import {RootStoreContext} from "./core/stores/rootStore";
import ModalContainer from "./core/common/modals/ModalContainer";
import HomePage from "./features/home/HomePage";
import Layout from "./core/layout/Layout";
import RoutesSwitcher from "./core/layout/routes/RoutesSwitcher";
import {useInitApp} from "./core/hooks/useInitApp";


interface DetailParams {
    traffic: string;
}

const App: FunctionComponent<RouteComponentProps<DetailParams>> = ({match, location, history}) => {
    const rootStore = useContext(RootStoreContext)
    const { appLoaded } = rootStore.commonStore
    const {currentTraffic} = rootStore.trafficsStore
    const {globalSettings} = rootStore.globalSettingsStore
    
    useInitApp()
    
    if (!appLoaded || currentTraffic === null || globalSettings.size === 0) return (<Dimmer active inverted>
        <Loader content='Učitavanje aplikacije...'/>
    </Dimmer>)

    return (
        <Fragment>
            <ModalContainer/>
            <ToastContainer position='bottom-right'/>
            <Route exact path='/' component={HomePage}/>
            <Route
                path={'/(.+)'}
                render={() => (
                    <Fragment>
                        <Layout>
                            <RoutesSwitcher location={location} />
                        </Layout>
                    </Fragment>
                )}
            />
        </Fragment>
    );
};

export default withRouter(observer(App));